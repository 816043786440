// Mood Board Color
export const BG_PRIMARY = '#EFF4EC';
export const BG_SECONDARY = '#E5E3CF';
export const BG_ALTERNATIVE = '#576536';
// Text Color
export const TEXT_PRIMARY = '#735C3D';
export const TEXT_SECONDARY = '#B18F68'; // Warana Coklat Tua sama dengan BG_ALTERNATIVE
export const TEXT_ALTERNATIVE = '#FFFFFF'; // Alternative Color
// Music & Navigation Color
export const NAVIGATION_COLOR = '#98512D'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#EDE0D1';
export const FOOTER_COLOR = '#7e6c696e';
// Default Button Color
export const BUTTON_GRADIENT = `linear-gradient(to left, #C1CDB7, #D9DCB3 140%)`;
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};
export const BUTTON_PADDING_PROPS = {
  ...BUTTON_PROPS,
  variant: 'solid',
  padding: '4px 16px',
  borderRadius: '16px',
  fontWeight: 'normal',
  textTransform: 'uppercase',
  background: BUTTON_GRADIENT,
  color: 'mainColorText',
  letterSpacing: '1px',
  size: 'sm',
  boxShadow: 'sm',
};

export const DEFAULT_BUTTON_PROPS = {
  fontWeight: 'normal',
};
